exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-impressum-tsx": () => import("./../../../src/pages/impressum.tsx" /* webpackChunkName: "component---src-pages-impressum-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-med-journal-privacy-policy-tsx": () => import("./../../../src/pages/med-journal/privacy_policy.tsx" /* webpackChunkName: "component---src-pages-med-journal-privacy-policy-tsx" */),
  "component---src-pages-med-journal-release-notes-hub-tsx": () => import("./../../../src/pages/med-journal/release-notes/hub.tsx" /* webpackChunkName: "component---src-pages-med-journal-release-notes-hub-tsx" */),
  "component---src-pages-med-journal-terms-data-processing-agreement-tsx": () => import("./../../../src/pages/med-journal/terms/data_processing_agreement.tsx" /* webpackChunkName: "component---src-pages-med-journal-terms-data-processing-agreement-tsx" */),
  "component---src-pages-med-journal-terms-terms-and-conditions-tsx": () => import("./../../../src/pages/med-journal/terms/terms_and_conditions.tsx" /* webpackChunkName: "component---src-pages-med-journal-terms-terms-and-conditions-tsx" */),
  "component---src-pages-privacy-policy-tsx": () => import("./../../../src/pages/privacy_policy.tsx" /* webpackChunkName: "component---src-pages-privacy-policy-tsx" */),
  "component---src-pages-sleep-journal-customer-tsx": () => import("./../../../src/pages/sleep_journal/customer.tsx" /* webpackChunkName: "component---src-pages-sleep-journal-customer-tsx" */),
  "component---src-pages-sleep-journal-index-tsx": () => import("./../../../src/pages/sleep-journal/index.tsx" /* webpackChunkName: "component---src-pages-sleep-journal-index-tsx" */),
  "component---src-pages-sleep-journal-privacy-policy-tsx": () => import("./../../../src/pages/sleep-journal/privacy_policy.tsx" /* webpackChunkName: "component---src-pages-sleep-journal-privacy-policy-tsx" */),
  "component---src-pages-sleep-journal-release-notes-decoder-tool-tsx": () => import("./../../../src/pages/sleep-journal/release-notes/decoder_tool.tsx" /* webpackChunkName: "component---src-pages-sleep-journal-release-notes-decoder-tool-tsx" */),
  "component---src-pages-sleep-journal-terms-data-processing-agreement-tsx": () => import("./../../../src/pages/sleep-journal/terms/data_processing_agreement.tsx" /* webpackChunkName: "component---src-pages-sleep-journal-terms-data-processing-agreement-tsx" */),
  "component---src-pages-sleep-journal-terms-terms-and-conditions-tsx": () => import("./../../../src/pages/sleep-journal/terms/terms_and_conditions.tsx" /* webpackChunkName: "component---src-pages-sleep-journal-terms-terms-and-conditions-tsx" */)
}

